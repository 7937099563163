<template>
  <div></div>
</template>
  <script>
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {},
  data() {
    return {
      code: "",
      cid: "",
      wrong: "",
      did: "",
      qrcode: "",
      nickname: "",
      avatar: "",
      channl: "",
    };
  },
  mounted() {
    document.title = "微信认证";
    this.rid = this.$route.query.rid; //推广者微信
    this.cid = this.$route.query.cid;
    this.did = this.$route.query.did;
    this.type = this.$route.query.type;
    this.channl = this.$route.query.channl;
    useJwt
      .Wxscheme({
        cid: this.cid,
        did: this.did,
        rid: this.rid,
        type: this.type,
        channl: this.channl,
      })
      .then((res) => {
        if (res.data.code == "0") {
          var url = res.data.data;
          window.location.href = url;
        }
      });
  },
};
</script>